import React, { FC, Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

export type ModalProps = {
  title?: string;
  children?: ReactNode;
  isOpen?: boolean;
  onClose: () => void;
  size?: string;
  renderFooter?: () => ReactNode;
  displayHeader?: boolean;
};

const Modal: FC<ModalProps> = ({
  title,
  children,
  isOpen,
  onClose,
  size = "max-w-lg",
  renderFooter = null,
  displayHeader = true,
}) => {
  return (
    <>
      <Transition appear show={!!isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className={clsx(
                  "fixed",
                  "inset-0",
                  "bg-gray-500",
                  "bg-opacity-75",
                  "transition-opacity"
                )}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  "inline-block",
                  "w-full",
                  "my-8",
                  "overflow-hidden",
                  "text-left",
                  "align-middle",
                  "transition-all",
                  "transform",
                  "bg-white",
                  "shadow-xl",
                  "rounded-md",
                  size
                )}
              >
                {displayHeader && (
                  <div
                    className={clsx(
                      "grid",
                      "grid-cols-[1fr_auto]",
                      "bg-gray-50",
                      "p-4"
                    )}
                  >
                    <Dialog.Title
                      as="h3"
                      className={clsx(
                        "text-base",
                        "text-gray-900",
                        "font-medium",
                        "leading-6"
                      )}
                    >
                      {title}
                    </Dialog.Title>
                    <button
                      type="button"
                      className={clsx(
                        "rounded-md",
                        "text-gray-400",
                        "hover:text-gray-500",
                        "focus:outline-none",
                        "focus:ring-2",
                        "focus:ring-offset-2",
                        "focus:ring-indigo-500"
                      )}
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                )}

                <div className="p-4">{children}</div>
                <div data-id="footer">{renderFooter && renderFooter()}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
